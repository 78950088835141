export const GtinMixin = {
  methods: {
    clearPoints(event, product) {
      let text = event;
      if (text) {
        text = text.replace(/[^0-9]/g, "");
        this.$nextTick(() => {
          product.gtin = text;
        });
      }
    },
    validGtin14(event, product) {
      let text = product.gtin.toString().replace(/[^0-9]/g, "");

      this.$nextTick(() => {
        if (text.length > 13) {
          product.gtin = text.slice(0, -1);
        } else if (text.length === 13) {
          let sum = 0;
          for (let i = 0; i < text.length; i++) {
            let digit = parseInt(text[i], 10);
            sum += digit * ((i % 2 === 0) ? 3 : 1);
          }
          let checkDigit = (10 - (sum % 10)) % 10;
          product.gtin = text + checkDigit;
        }
      });
    },
    validGtin14Delete(event, product) {
      if (event.keyCode === 8) {
        this.$nextTick(() => {
          let text = product.gtin ? product.gtin.toString() : '';
          if (text.length === 14) {
            product.gtin = text.slice(0, -1);
          }
        });
      }
    },
  }
};
