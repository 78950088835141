<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.product") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
        :headers="headers"
        :search="search"
        :items="products"
        v-model="selectedItems"
        :show-select="showCheckBox"
        :loading="loading"
        :options.sync="options"
        :no-results-text="$t('general.ui.not_search_found')"
        :no-data-text="$t('general.ui.not_data_found')"
        :loading-text="$t('general.notification.loadData')"
        :server-items-length="totalFromServer"
        :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="12" xl="4">
              <v-text-field
                  dense
                  filled
                  rounded
                  color="secondary"
                  v-model="search"
                  :clearable="true"
                  @keypress.enter="searchForText"
                  @click:clear="searchAll"
                  @click:append="searchForText"
                  append-icon="mdi-magnify"
                  :label="$t('general.titles.filter')"
                  single-line
                  hide-details
              />
            </v-col>
            <v-col lg="4" md="4" sm="12" xl="4">
              <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 1.5em;"
                  @click="checkBoxShow"
                  class="mr-1"
                  :disabled="!(products && products.length > 0)"
              >
                <span v-if="!showCheckBox">
                  <v-icon small color="#6D6D6D" left
                  >mdi-checkbox-multiple-marked-outline</v-icon
                  >
                  <span style="color: #6D6D6D">{{
                      $t("general.buttons.checksBoxEnable")
                    }}</span>
                </span>
                <span v-else>
                  <v-icon small color="#6D6D6D" left
                  >mdi-checkbox-multiple-marked</v-icon
                  >
                  <span>{{ $t("general.buttons.checksBoxDisable") }}</span>
                </span>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
                lg="4"
                md="4"
                sm="8"
                xl="4"
                class="text-end d-flex justify-end"
            >
              <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 1.5em 0 0 1.5em;"
                  @click="exportCSVProducts"
                  v-if="check([{ domain: 'Products', permisions: ['Write'] }])"
                  :loading="loadingExport"
                  class="mr-1"
              >
                <v-icon small color="#6D6D6D" left
                >mdi-download-multiple
                </v-icon
                >
                <span style="color:#6D6D6D">{{
                    $t("general.buttons.export")
                  }}</span>
                <template v-slot:loader>
                  <v-progress-circular
                      style="position: absolute;
                                                   margin-left: -3px;
                                                   height: 15px;"
                      color="secondary accent-4"
                      indeterminate
                      rounded
                  />
                </template>
              </v-btn>

              <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                      small
                      tile
                      :elevation="0"
                      color="#d8d8d896"
                      style="border-radius: 0 1.5em 1.5em 0;"
                      v-if="check([{ domain: 'Culture', permisions: ['Write'] }])"
                      v-on="on"
                  >
                    <v-icon small color="#6D6D6D" left>mdi-plus</v-icon>
                    {{ $t("general.buttons.new") }}
                  </v-btn>
                </template>
                <v-card :light="true" color="white">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12" class="d-flex justify-end">
                            <v-switch
                                v-model="editedItem.active"
                                color="secondary"
                            >
                              <template v-slot:prepend>
                                <v-label ref="label"
                                >{{ $t("product.fields.active") }}
                                </v-label>
                              </template>
                            </v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field onkeypress="return (event.charCode != 34)" v-on:keyup.86="replaceProductName"
                                          v-on:keyup.ctrl.86="replaceProductName"
                                          color="secondary"
                                          v-model="editedItem.product_name"
                                          required
                                          class="required"
                                          :rules="requireAndMaxRules(max)"
                                          :counter="max"
                                          maxlength="max"
                            >
                              <template v-slot:label>
                                {{ $t('product.fields.name') }} <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field onkeypress="return (event.charCode != 34)"
                                          v-on:keyup.ctrl.86="replaceCategoryName"
                                          v-on:keyup.86="replaceCategoryName"
                                          color="secondary"
                                          v-model="editedItem.category_name"
                                          required
                                          class="required"
                                          :rules="requireRules"
                            >
                              <template v-slot:label>
                                {{ $t('product.fields.category') }} <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field onkeypress="return (event.charCode != 34)"
                                          v-on:keyup.86="replaceGtin"
                                          v-on:keyup.ctrl.86="replaceGtin"
                                          color="secondary"
                                          v-model="editedItem.gtin"
                                          required
                                          class="required"
                                          type="number"
                                          @input="clearPoints($event, editedItem)"
                                          @keyup="validGtin14($event, editedItem)"
                                          @keydown="validGtin14Delete($event, editedItem)"
                                          :rules="requireAndGTIN14Rules(13)"
                            >
                              <template v-slot:label>
                                {{ $t('product.fields.codeGTIN') }} <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea onkeypress="return (event.charCode != 34)"
                                        v-on:keyup.86="replaceDescriptionText"
                                        v-on:keyup.ctrl.86="replaceDescriptionText"
                                        v-model="editedItem.description_text"
                                        counter
                                        maxlength="250"
                                        :rules="lengthRules(250).concat(limitEnterKeyPress)"
                                        color="secondary"
                                        :auto-grow="true"
                                        :clearable="true"
                                        :label="$t('product.fields.description')"
                                        :no-resize="true"
                                        :row-height="40"
                                        :rows="3"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <upload-evidence :nomenclator="editedItem" :loading="loadingEvidences"/>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small tile outlined color="gray" @click="close">
                      {{ $t("general.buttons.cancel") }}
                    </v-btn>
                    <v-btn
                        small
                        tile
                        outlined
                        color="secondary"
                        :disabled="!valid"
                        @click="save"
                    >
                      {{ $t("general.buttons.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                color="neuter"
                small
                class="mr-2"
                @click="showItem(item)"
                v-if="check([{ domain: 'Culture', permisions: ['Update'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.show") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.product") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                color="neuter"
                small
                class="mr-2"
                @click="barcode(item)"
            >
              mdi-qrcode
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.qrcode") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.product") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                v-if="check([{ domain: 'Culture', permisions: ['Update'] }])"
                color="neuter"
                class="mr-2"
                small
                @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.product") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                v-if="check([{ domain: 'Culture', permisions: ['Delete'] }])"
                color="neuter"
                small
                @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.product") }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon
            small
            class="mr-4"
            color="secondary"
            v-if="item.active"
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class="mr-4" color="neuter" v-else>
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.description_text="{ item }">
        <div
            class="mr-4"
            v-if="item.description_text.toUpperCase() === 'NULL'"
        >
          -
        </div>
        <div class="mr-4" v-else style="width: 200px; overflow-wrap: break-word;">
            <ReadMore
              :classes="'mb-0'"
              :font-size="14"
              :max-chars="50"
              :text="item.description_text"
            />
        </div>
      </template>
      <template v-slot:item.category_name="{ item }">
        <span class="mr-4" v-if="item.category_name.toUpperCase() === 'NULL'">
        </span>
        <span class="mr-4" v-else>
          {{ item.category_name }}
        </span>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px" scrollable>
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="secondary" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showDialogLabel" persistent width="600px" scrollable>
        <v-form ref="form" v-model="valid2">
          <v-card>
            <v-card-title class="headline">{{
                $t("product.label.confLabel")
              }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete :no-data-text="$t('general.fields.noDataAvailable')"
                                  :clearable="true"
                                  color="secondary"
                                  item-color="secondary"
                                  :items="getPrintFormatList"
                                  :label="$t('records.label.titles.printType')"
                                  :rules="requireRules"
                                  class="required"
                                  item-text="type"
                                  persistent-hint
                                  v-model="labelModel.printType"
                                  small-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field onkeypress="return (event.charCode != 34)"
                                v-on:keyup.86="replaceLot"
                                v-on:keyup.ctrl.86="replaceLot"
                                color="secondary"
                                v-model="labelModel.productsData[0].lot"
                                :label="$t('product.label.lot')"
                                required
                                class="required"
                                :rules="requireAndLotRules"
                  />
                </v-col>

                <v-col cols="6">
                   <v-text-field
                      color="secondary"
                      :label="$t('product.label.netWeight')"
                      required
                      class="required"
                      v-model="labelModel.productsData[0].netWeight"
                      :rules="maxRules(6, labelModel.productsData[0].netWeight).concat(onlyPositiveNumberFormat)"
                       @focus="
                        labelModel.productsData[0].netWeight === 0
                          ? (labelModel.productsData[0].netWeight = '')
                          : labelModel.productsData[0].netWeight
                      "
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete :no-data-text="$t('general.fields.noDataAvailable')"
                                  color="secondary"
                                  item-color="secondary"
                                  return-object
                                  clearable
                                  :items="orderDatas(allUnits,'unit_name')"
                                  :label="$t('product.label.unit')"
                                  item-text="unit_name"
                                  small-chips
                                  item-value="id"
                                  persistent-hint
                                  v-model="labelModel.productsData[0].unit"
                                  required
                                  class="required"
                                  :rules="requireRules"
                  >
                    <template
                        v-slot:selection="{
                        item,
                        index,
                        attrs,
                        select,
                        selected,
                      }"
                    >
                      <v-chip small v-if="index === 0">
                        <span>{{ item.unit_name }}</span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-menu
                      v-model="menuRecord"
                      :ref="menuRecord"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      color="secondary"
                  >
                    <template v-slot:activator="{ on }">
                      <v-combobox :no-data-text="$t('general.fields.noDataAvailable')"
                                  hide-details
                                  v-model="computedExpirationDate"
                                  :label="$t('product.label.expirationDate')"
                                  v-on="on"
                                  color="secondary"
                                  append-icon="mdi-calendar-text"
                                  @click:append="() => (menuRecord = !menuRecord)"
                                  small-chips
                      />
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      color="secondary"
                      v-model="labelModel.productsData[0].expirationDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          small
                          text
                          color="secondary"
                          @click="menuRecord = false"
                      >
                        {{ $t("general.buttons.confirm") }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea onkeypress="return (event.charCode != 34)"
                              v-on:keyup.ctrl.86="replaceAdditionalInformation"
                              v-on:keyup.86="replaceAdditionalInformation"
                              color="secondary"
                              v-model="labelModel.productsData[0].additionalInformation"
                              :label="$t('product.label.details')"
                              :auto-grow="true"
                              :clearable="true"
                              :no-resize="true"
                              :row-height="20"
                              :rows="3"
                              counter
                              :rules="limitEnterKeyPress"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small text color="gray" @click="dialogLabelClose()">
                {{ $t("general.buttons.cancel") }}
              </v-btn>
              <v-btn
                  small
                  text
                  color="secondary"
                  :disabled="!valid2"
                  @click="printRecordLabel()"
              >
                {{ $t("general.buttons.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog
          v-if="editedItem"
          v-model="showItemDetailsDialog"
          persistent
          width="600px"
          scrollable
      >
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title
                style="display: flex; justify-content: space-between; align-content: center; align-items: flex-start;"
            >
              {{ editedItem.product_name }}
              <v-spacer></v-spacer>
              {{ $t("product.fields.category") }}:
              {{ editedItem.category_name }}
            </v-card-title>

            <v-card-subtitle>
              <div style="display: flex">
                <p class="mr-2">{{ $t("product.fields.codeGTIN") }}:</p>
                {{ editedItem.gtin }}
              </div>
            </v-card-subtitle>

            <v-divider light></v-divider>
            <v-card-text>
              <div class="row">
                <div class="col-12">
                  <div
                      style="background-color: #03AC8C; border-radius: 5px; padding:7px; text-align: start;"
                  >
                    <h3 style="padding-left: 5px; color: white">
                      {{ $t("product.fields.description") }}
                    </h3>
                  </div>
                  <div
                    class="mt-2"
                    style="background-color: #f8f8f8; border-radius: 5px; padding:7px; text-align: start;"
                  >
                    {{ editedItem.description_text || '-' }}
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-divider light></v-divider>
            <v-card-actions class="pa-4">
              <v-btn
                  v-if="editedItem.evidences && editedItem.evidences.length > 0"
                  text
                  :loading="loadingDownloadEvidences"
                  :disabled="loadingDownloadEvidences"
                  color="blue-grey"
                  class="ma-2 white--text"
                  small
                  @click="downloadEvidence(editedItem)"
              >
                <v-icon color="secondary">
                  mdi-download-multiple
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn small text color="gray" @click="close">
                {{ $t("general.buttons.close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import i18n from "@/plugins/i18n";
import {mapActions, mapGetters} from "vuex";
import {GtinMixin} from "@/mixins/gtin.mixin";
import LanguajeService from "../../../services/LanguajeService";
import PermisionService from "../../../services/PermisionService";
import ProductService from "../../../services/ProductService";
import MoreOptions from "../../../components/MoreOptions";
import UploadEvidence from "../../../components/UploadEvidence";
import sortList from '@/services/OrderListService'
import ReadMore from "@/components/ReadMoreComponent.vue";

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  components: {ReadMore, UploadEvidence, MoreOptions},
  mixins: [GtinMixin],
  data: () => ({
    valueDate: null,
    menu: false,
    langs: [],
    profile: null,
    dialog: false,
    editedIndex: -1,
    search: "",
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    editedItem: {
      product_name: "",
      gtin: "",
      category_name: "",
      description_text: "",
      active: true,
      evidences: [],
    },
    labelModel: {
      productsData: [
        {
          product: null,
          lot: null,
          expirationDate: null,
          netWeight: null,
          unit: null,
          additionalInformation: "",
          form: {

          }
        },
      ],
      labelType: {
        id: 5,
        text: i18n.t("records.labelsTypes.product"),
      },
      printType: "",
    },
    defaultItem: {
      active: true,
    },
    showDialog: false,
    showDialogLabel: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    valid1: false,
    valid2: false,
    max: 60,
    deletingItem: {},
    showCheckBox: false,
    checkGTINActions: false,
    menuRecord: false,
    selectedItems: [],
    isEnabledCheckBox: false,
    selectedItem: null,
    showItemDetailsDialog: false,
    loadingEvidences: false,
    loadingDownloadEvidences: false,
    loadingExport: false,
    options: {},
    defaultFilters: {
      companyId: PROFILE.company_id,
      search: "",
      pageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'id',
        direction: 'DESC',
      }
    },
  }),

  computed: {
    notification: {
      get() {
        return this.$store.getters["ui/GET_NOTIFICATION"];
      },
      set(val) {
        this.$store.commit("ui/NOTIFICATION", val);
      },
    },
    ...mapGetters({
      products: "product/products",
      totalFromServer: "product/totalFromServer",
      evidences: "documents/evidences",
      loading: "product/loading",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireAndGTIN14Rules: "general/requireAndGTIN14Rules",
      limitEnterKeyPress: "general/limitEnterKeyPress",
      lengthRules: "general/lengthRules",
      requireRules: "general/requireRules",
      maxRules: "general/maxRules",
      onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat',
      requireAndLotRules: "general/requireAndLotRules",
      allUnits: "units/allObjectUnits",
      labelsEnum: "labels/labelsEnum",
      languageStructure: "general/languageStructure",
      equalObjectsCompareRule: "general/equalObjectsCompareRule"
    }),
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
    },
    headers: () => [
      {text: i18n.t("product.fields.name"), value: "product_name", sortable: false},
      {text: i18n.t("product.fields.codeGTIN"), value: "gtin"},
      {text: i18n.t("product.fields.category"), value: "category_name", sortable: false},
      {text: i18n.t("product.fields.description"), value: "description_text", sortable: false},
      {
        text: i18n.t("product.fields.active"),
        value: "active",
        align: "center",
      },
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 140,
        sortable: false,
        align: "center",
      },
    ],
    formTitle() {
      return (
          (this.editedIndex === -1
              ? i18n.t("general.titles.new")
              : i18n.t("general.titles.edit")) +
          " " +
          i18n.t("product.titles.new_product")
      );
    },
    computedExpirationDate: {
      get() {
        return this.labelModel.productsData[0].expirationDate
            ? moment(this.labelModel.productsData[0].expirationDate).format(
                "DD / MM / YYYY"
            )
            : (this.labelModel.productsData[0].expirationDate = "");
      },
      set(data) {
        this.labelModel.productsData[0].expirationDate = "";
      },
    },
    getPrintFormatList() {
      return [{type:'SIMPLE'},{type:'DUPLO'}]
    },
    optionsTable: {
      get() {
        return this.$store.getters['ui/GET_OPTIONS_TABLE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
      },
    },
  },

  created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    this.initialize();
  },

  watch: {
    options: {
      async handler() {
        this.optionsTable = this.options
        await this.searchText()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      fetchListProduct: "product/fetchListProduct",
      fetchAllUnits: "units/fetchAllUnits",
      saveProduct: "product/saveProduct",
      deleteProduct: "product/deleteProduct",
      clearEvidences: "documents/clearEvidences",
      exportCSV: "product/exportCSV",
    }),
    async searchAll() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'id',
          direction: 'DESC',
        }
      }

      filters.companyId = this.profile.company_id

      await this.fetchListProduct([filters, this.$toast])
    },
    async searchForText() {
      this.options.page = 1
      await this.searchText()
    },
    async searchText() {
      let filters = {
        companyId: null,
        search: null,
        pageableDTO: {
          page: null,
          size: null,
          sortBy: 'id',
          direction: 'DESC'
        }
      }

      filters.companyId = this.profile.company_id

      const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
              ? 'asc'
              : 'desc'
      const sort =
          this.options.sortBy.length === 0
              ? 'id'
              : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
      filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      }

      if (this.search && this.search !== '')
        filters.search = this.search

      await this.fetchListProduct([filters, this.$toast])
    },
    orderDatas( list, order){
        return sortList.orderListByUppercase( list, order);
    },
    initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));

      this.searchAll();
      this.fetchAllUnits(this.$toast);
    },
    check(permissions) {
      return PermisionService.check(permissions);
    },
    showItem(item) {
      this.selectedItem = item;
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.evidences && item.evidences.length > 0) {
        this.getEvidences(this.editedItem);
      }
      this.showItemDetailsDialog = true;
    },
    getByProperty(array, property) {
      return _.map(array, property);
    },
    async exportCSVProducts() {
      this.loadingExport = true;
      const data = {
        language: LanguajeService.getLenguajeName(),
        products_ids: this.getByProperty(this.selectedItems, "id"),
      };
      await this.exportCSV([data, this.$toast]).finally(() => {
        this.loadingExport = false;
      });
    },
    editItem(item) {
      this.selectedItem = item;
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.evidences && item.evidences.length > 0) {
        this.getEvidences(this.editedItem);
      }
      this.dialog = true;
    },
    checkBoxShow() {
      this.showCheckBox = !this.showCheckBox;
      if (!this.showCheckBox) this.selectedItems = [];
    },
    downloadEvidence(item) {
      this.loadingDownloadEvidences = true;
      ProductService.getEvidences(item)
          .then((response) => {
            const FileSaver = require("file-saver");
            const blob = new Blob([response.data], {
              type: "application/zip",
            });
            FileSaver.saveAs(blob, `product_${item.gtin}_evidences.zip`);

            this.$toast.success(
                i18n.t("general.notification.successDownloadEvidences"),
                {
                  queueable: true,
                  color: "secondary",
                }
            );
          })
          .catch((error) => {
            this.$toast.warning(
                i18n.t("general.notification.errorDownloadEvidences"),
                {
                  queueable: true,
                  color: "#e83b36",
                }
            );
          })
          .finally(() => {
            this.loadingDownloadEvidences = false;
          });
    },
    getEvidences(editedItem) {
      const scope = this;
      scope.loadingEvidences = true;
      try {
        ProductService.getEvidences(editedItem)
            .then((response) => {
              const blob = new Blob([response.data], {
                type: "application/zip",
              });
              const jsZip = require("jszip");
              jsZip.loadAsync(blob).then((zip) => {
                Object.keys(zip.files).forEach((filename) => {
                  zip.files[filename].async("blob").then((fileData) => {
                    // These are your file contents
                    let type = "";
                    const nameSpliter = filename.split(".");
                    if (nameSpliter[1] === "pdf" || nameSpliter[1] === "PDF") {
                      type = "application/pdf";
                    } else if (
                        nameSpliter[1] === "xlsx" ||
                        nameSpliter[1] === "XLSX"
                    ) {
                      type =
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    } else if (
                        nameSpliter[1] === "xls" ||
                        nameSpliter[1] === "XLS"
                    ) {
                      type = "application/vnd.ms-excel";
                    } else {
                      type = "image/" + nameSpliter[1];
                    }
                    scope.$store.commit(
                        "documents/SET_EVIDENCES",
                        new File([fileData], filename, {type: type})
                    );
                  });
                });
              });
            })
            .finally(() => {
              scope.loadingEvidences = false;
            });
      } catch (error) {
        scope.$toast.warning(
            i18n.t("general.notification.loadEvidencesError"),
            {
              queueable: true,
              color: "#e83b36",
            }
        );
      }
    },
    deleteItem() {
      this.dialogClose();
      this.deleteProduct([this.deletingItem.id, this.$toast]).finally(() => {
        this.fetchListProduct([this.defaultFilters, this.$toast]);
      });
    },
    close() {
      if (this.dialog === true) this.dialog = false;
      if (this.showItemDetailsDialog === true)
        this.showItemDetailsDialog = false;
      this.$refs.form.reset();
      this.clearEvidences();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (
          this.equalObjectsCompareRule(this.selectedItem, this.editedItem) &&
          this.editedItem.evidences.length === this.evidences.length
      ) {
        this.$toast.error(i18n.t("general.notification.nothingToModify"), {
          queueable: true,
          color: "secondary",
        });
      } else {
        if (!this.editedItem.description_text)
          this.editedItem.description_text = "";

        let item = Object.assign({}, this.editedItem);
        // Translate
        item.language_key = LanguajeService.setKey3(
            item.product_name,
            item.language_key,
            !item.id ? this.languageStructure : null
        );
        item.description = LanguajeService.setKey3(
            item.description_text,
            item.description,
            !item.id ? this.languageStructure : null
        );
        item.category = LanguajeService.setKey3(
            item.category_name,
            item.category,
            !item.id ? this.languageStructure : null
        );
        // *****
        item.companyId = this.profile.company_id;

        this.saveProduct([
          item,
          this.evidences,
          this.editedIndex,
          this.$toast,
        ]).finally(() => {
          this.fetchListProduct([this.defaultFilters, this.$toast]);
        });
        this.close();
      }
    },
    barcode(item) {
      this.labelModel.productsData[0].product = item;
      this.showDialogLabel = true;
    },
    dialogLabelClose() {
      this.$refs.form.reset();
      this.showDialogLabel = false;
    },
    printRecordLabel() {
      this.labelModel.labelType = this.labelModel.printType ==='SIMPLE'?  {
        id: 8,
        text: i18n.t("records.labelsTypes.product2"),
      } : {
        id: 5,
        text: i18n.t("records.labelsTypes.product"),
      };
      this.$store.commit("general/LABEL_DATA", _.cloneDeep(this.labelModel));
      this.$router.push({path: "/printRecordLabel/product"});
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },
    replace(event) {
      event.currentTarget.value = event.currentTarget.value.toString().replaceAll('"', '')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceProductName(event){
      this.editedItem.product_name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceCategoryName(event){
      this.editedItem.category_name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceGtin(event){
      this.editedItem.gtin =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceDescriptionText(event){
      this.editedItem.description_text =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceLot(event){
      this.labelModel.productsData[0].lot =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceNetWeight(event){
      this.labelModel.productsData[0].netWeight =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceAdditionalInformation(event){
      this.labelModel.productsData[0].additionalInformation =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
  destroyed() {
    this.notification = false;
  },

};
</script>

<style scoped></style>
