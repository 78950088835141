<template>
  <v-container>
    <v-col class="pa-0" cols="12">
      <div style="background-color: #03AC8C; border-radius: 5px; padding:7px; text-align: start;">
        <h3 style="padding-left: 5px; color: white">
          {{ $t('general.titles.evidences') }}
          <v-icon color="white" small>mdi-pencil</v-icon>
        </h3>
      </div>
      <div v-if="evidences && evidences.length > 0 && !loading" class="mt-2"
           style="background-color: #f8f8f8; border-radius: 5px; padding:7px; text-align: start;"
      >
        <v-list-item v-for="(doc, index) in evidences" :key="index">
          <v-list-item-icon>
            <v-icon color="indigo">
              {{ selectIconFile(doc.name) }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ doc.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ size(doc.size) }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action style="display: contents">
            <v-btn v-if="doc.url" color="secondary" icon>
              <a :href="doc.url" target="_blank">
                <v-icon color="secondary">mdi-download-multiple</v-icon>
              </a>
            </v-btn>
            <v-btn color="danger" icon @click="removeEvidence(index)">
              <v-icon color="secondary">mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
      <div v-else-if="loading" class="mt-2"
           style="background-color: #f8f8f8; border-radius: 5px; padding:7px; text-align: start;"
      >
        <v-card elevation="0" >
          <v-card-text class="d-flex justify-center">
            <v-progress-circular indeterminate size="20" width="3" />
          </v-card-text>
        </v-card>
      </div>
    </v-col>
    <v-col class="pa-0 mt-5" cols="12">
      <v-file-input
        v-model="filesSelected"
        :error-messages="duplicatedFile"
        :label="$t('general.buttons.upload_doc')"
        :rules="fileTypeRulesAndSize(filesSelected)"
        color="secondary"
        multiple
        @change="addEvidences"
      >
      </v-file-input>
    </v-col>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DocumentsService from "../services/DocumentsService";

export default {
  name: "UploadEvidence",
  data() {
    return {
      filesSelected: [],
      duplicatedFile: ''
    }
  },
  props: {
    nomenclator: {
      type: Object,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    evidences() {
      return this.$store.getters['documents/evidences']
    },
    ...mapGetters({
      fileTypeRulesAndSize: 'general/fileTypeRulesAndSize'
    }),
  },
  methods: {
    ...mapActions({
      addEvidences: 'documents/addEvidences',
      removeEvidence: 'documents/removeEvidence'
    }),
    size(size) {
      return DocumentsService.formatterSizeUnit(size)
    },
    selectIconFile(name) {
      const extension = DocumentsService.getFileExtension(name);
      switch (extension[0]) {
        case 'pdf':
          return 'mdi-file-pdf-box';
        case 'xlsx':
          return 'mdi-file-excel';
        case 'xls':
          return 'mdi-file-excel';
        case 'cvs':
          return 'mdi-file-table';
        case 'png':
          return 'mdi-file-image';
        case 'jpg':
          return 'mdi-file-image';
        case 'jpeg':
          return 'mdi-file-image';
        case 'bmp':
          return 'mdi-file-image';
        case 'msword':
          return 'mdi-file-image';
        case 'zip':
          return 'mdi-folder-zip';
        case 'rar':
          return 'mdi-folder-zip';
      }
    },
  }
}
</script>

<style scoped>

</style>
